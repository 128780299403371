
import {ROLE_LABELS} from '@/plugins/constants';

export default {
  layout: 'auth',
  middleware: 'guest',
  data: () => ({
    formName: 'login',
    formData: {},
    loggingIn: false,
  }),
  methods: {
    async submit() {
      this.loggingIn = true;
      try {
        const {data} = await this.$auth.loginWith('local', {data: this.formData});
        this.$gtm.push({
          event: 'login',
          user_id: data.data.uuid,
          user_role: ROLE_LABELS[this.$store.$auth.user.role_id],
          interal_user: this.$store.$auth.user.is_internal ? 'true' : 'false',
        });

        await this.$router.push(this.$route.query.redirect || this.localeRoute({name: 'index'}));
      } catch (err) {
        this.$formulate.handle(err, this.formName);
      } finally {
        this.loggingIn = false;
      }
    },
  },
};
